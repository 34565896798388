import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "click--collect"
    }}>{`Click & Collect`}</h1>
    <hr></hr>
    <p>{`Click & Collect is a feature that allows you to facilitate "touchless fulfillment" of library items for your  patrons. This can involve delivering materials to the patron's car, a designated pickup location, or another method that suits the needs of your library and its users. Detailed below are the steps taken by a patron to request pickup. `}<em parentName="p">{`Please note that the examples below may vary from what you have configured for your own contactless pickup feature:`}</em></p>
    <ol>
      <li parentName="ol">{`Once an item is ready for pickup, `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      